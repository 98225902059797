<template>
  <div class="">  
    <div>
      <div class="fs-sp tima-app">
        <div class="w-100">
          <div class="w-100 b2c-bg-top">
            <div class="b2c-top-left" v-if="submitted"></div>
            <a class="b2c-top-left" v-else href="/itshop/tai-chinh-bao-hiem"></a>
            <div class="b2c-top-center">VAY TIÊU DÙNG</div>
            <div class="b2c-top-right"></div>
          </div>
          <img src="@/assets/images/shop/partner/tima_app.png" class="b2c-top-img"/>
          <div class="fs-inr">
            <div class="card p-0 tima" v-if="!submitted">
              <div class="card-body p-0 d-flex">
                <div class="tima-right flex-fill">
                  <validation-observer ref="infoValid">
                    <div class="row">
                      <div class="col-12 text-center"><img width="120" src="@/assets/images/shop/partner/tima2.png"/></div>
                      <div class="col-12"><h3 class="tima-header">ĐĂNG KÝ VAY NHANH</h3></div>
                      <div class="col-12">
                        <validation-provider #default="{ errors }" rules="required">
                          <div :class="errors.length > 0 || (item.full_name==null && submitCount>0) ? 'is-invalid' : ''">
                            <input class="tima-input" v-model="item.full_name" placeholder="Họ và tên *"/>
                          </div>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <validation-provider #default="{ errors }" rules="required">
                          <div :class="errors.length > 0 || (item.cccd==null && submitCount>0) ? 'is-invalid' : ''">
                            <input class="tima-input" v-model="item.cccd" placeholder="Nhập CMND/CCCD *"/>
                          </div>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <validation-provider #default="{ errors }" rules="required|phoneNumber">
                          <div :class="errors.length > 0 || (item.phone==null && submitCount>0) ? 'is-invalid' : ''">
                            <input class="tima-input" v-model="item.phone" placeholder="Số điện thoại *"/>
                          </div>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <validation-provider #default="{ errors }" rules="required|email">
                          <div :class="errors.length > 0 || (item.email==null && submitCount>0) ? 'is-invalid' : ''">
                            <input class="tima-input" v-model="item.email" placeholder="Địa chỉ email *"/>
                          </div>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <validation-provider v-if="provinces" #default="{ errors }" rules="required|min0">
                          <v-select label="provinceName" :class="errors.length > 0 || (!province_id && submitCount>0) ? 'is-invalid' : ''" placeholder="Tỉnh/ TP đang sống *" v-model="province_id"
                                  :options="provinces" :reduce="p => p.provinceId"></v-select>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <validation-provider v-if="districts" #default="{ errors }" rules="required|min0">
                          <v-select :class="errors.length > 0 || (!district_id && submitCount>0) ? 'is-invalid' : ''" label="districtName" placeholder="Quận/Huyện *" v-model="district_id"
                                  :options="districts" :reduce="p => p.districtId"></v-select>
                        </validation-provider>
                      </div>
                      <div class="col-12">
                        <div :class="selected_package==0 && submitCount>0?'is-invalid':'valid'">
                          <input type="input" readonly class="tima-input-btn tima-input text-left" 
                            :value="getPackageLabel" placeholder="Chọn gói vay *" @click="isShowPackage=!isShowPackage"/></div>
                        </div>
                    </div>
                  </validation-observer>
                  <div class="text-center pt-2">
                    <button class="fs-but1 pl-4 pr-4 pt-1 pb-1" @click="applyForLoan">VAY NGAY</button>
                    <p class="text pt-1 font-italic">Bằng cách bấm VAY NGAY, bạn đồng ý với <a class="text-primary" @click="$bvModal.show('modal-tima')">điều khoản và điều kiện</a> của Tima</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="tim-success-msg app" v-if="!isFail">
                <p class="text-center pt-5"><svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M117.143 54.7758V60.033C117.136 72.3554 113.146 84.3454 105.768 94.2148C98.3898 104.084 88.019 111.304 76.2023 114.798C64.3856 118.292 51.756 117.872 40.1973 113.602C28.6385 109.332 18.7697 101.439 12.0629 91.1019C5.35609 80.7646 2.17051 68.5362 2.98129 56.2405C3.79206 43.9448 8.55575 32.2406 16.5619 22.8734C24.568 13.5062 35.3876 6.97803 47.4071 4.26239C59.4265 1.54676 72.0018 2.7892 83.2574 7.80442M117.143 14.3188L60.0003 71.5188L42.8574 54.3759" stroke="#23ED1F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></p>
                <h3>Đăng ký vay thành công</h3>
                <p class="text text-center">
                  Quý Khách đã đăng ký vay thành công với<br>số điện thoại {{ item.phone }}
                </p>
                <div class="tima-box-1">
                  Vui lòng giữ máy, Tima sẽ liên hệ ngay trong vòng 30 phút Giờ làm việc: 8h - 20h từ thứ 2 - chủ nhật (trừ ngày Lễ, Tết)
                </div>
                <p class="text text-center font-italic">
                  Khi cần hỗ trợ bạn vui lòng liên lạc số điện thoại 1900 633 688
                </p>
                <p class="text-center pt-2">
                  <a href="/" class="btn btn-primary mr-auto ml-auto">Về trang chủ</a>
                </p>
              </div>
              <div class="tim-success-msg app" v-else>
                <p class="text-center pt-5"><svg width="118" height="119" viewBox="0 0 118 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M76.1431 42.3159L41.8574 76.6016M41.8574 42.3159L76.1431 76.6016M116.143 59.4587C116.143 91.0178 90.5594 116.602 59.0003 116.602C27.4412 116.602 1.85742 91.0178 1.85742 59.4587C1.85742 27.8996 27.4412 2.31586 59.0003 2.31586C90.5594 2.31586 116.143 27.8996 116.143 59.4587Z" stroke="#ED1F24" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></p>
                <h3>Bạn có đơn vay đang chờ xử lý!</h3>
                <p class="text text-center">
                  Vui lòng liên hệ tới số:<br>
                  1900.633.688<br>
                  Để được hỗ trợ chi tiết
                </p>
                <p class="text-center pt-2">
                  <a @click="submitted=0;isFail=false" class="btn btn-primary mr-auto ml-auto">Thử lại</a>
                </p>
                <p class="text-center pt-2">
                  <a href="/" class="btn btn-secondary mr-auto ml-auto">Về trang chủ</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
        </div>
      </div>
      <div class=" fs-pc">
        <div class="b2c-shop-pc-header">
          <h2><svg v-if="submitted" role="button" @click="submitted=false" width="8" height="15" class="mr-1" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13.4587L1 7.45874L7 1.45874" stroke="#424242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <a v-else href="/itshop/tai-chinh-bao-hiem">
          <svg role="button" width="8" height="15" class="mr-1" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 13.4587L1 7.45874L7 1.45874" stroke="#424242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a> VAY TIÊU DÙNG</h2>
          <div class="nav"></div>
        </div>
        <div class="fs-inr">
          <div class="card p-0 tima" v-if="!submitted">
            <div class="card-body p-0 d-flex">
              <div class="tima-left"></div>
              <div class="tima-right flex-fill">
                <validation-observer ref="infoValid">
                  <div class="row">
                    <div class="col-12 text-center"><img width="120" src="@/assets/images/shop/partner/tima2.png"/></div>
                    <div class="col-12"><h3 class="tima-header">ĐĂNG KÝ VAY NHANH</h3></div>
                    <div class="col-12">
                      <validation-provider #default="{ errors }" rules="required">
                        <div  :class="errors.length > 0 || (item.full_name==null && submitCount>0) ? 'is-invalid' : ''">
                          <input class="tima-input" v-model="item.full_name" placeholder="Họ và tên *"/>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider #default="{ errors }" rules="required">
                        <div  :class="errors.length > 0 || (item.cccd==null && submitCount>0) ? 'is-invalid' : ''">
                          <input class="tima-input" v-model="item.cccd" placeholder="Nhập CMND/CCCD *"/>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider #default="{ errors }" rules="required|phoneNumber">
                        <div  :class="errors.length > 0 || (item.phone==null && submitCount>0) ? 'is-invalid' : ''">
                          <input class="tima-input" v-model="item.phone" placeholder="Số điện thoại *"/>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider #default="{ errors }" rules="required|email">
                        <div :class="errors.length > 0 || (item.email==null && submitCount>0) ? 'is-invalid' : ''">
                          <input class="tima-input" v-model="item.email" placeholder="Địa chỉ email *"/>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider v-if="provinces" #default="{ errors }" rules="required|min0">
                        <v-select label="provinceName" :class="errors.length > 0 || (province_id==null && submitCount>0) ? 'is-invalid' : ''" placeholder="Tỉnh/ TP đang sống *" v-model="province_id"
                                :options="provinces" :reduce="p => p.provinceId"></v-select>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider v-if="districts" #default="{ errors }" rules="required|min0">
                        <v-select :class="errors.length > 0 || (district_id==null && submitCount>0) ? 'is-invalid' : ''" label="districtName" placeholder="Quận/Huyện *" v-model="district_id"
                                :options="districts" :reduce="p => p.districtId"></v-select>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <div :class="selected_package==0 && submitCount>0?'is-invalid':'valid'">
                        <input type="input" readonly class="tima-input-btn tima-input text-left" 
                          :value="getPackageLabel" placeholder="Chọn gói vay *" @click="isShowPackage=!isShowPackage"/></div>
                      </div>
                  </div>
                </validation-observer>
                <div class="text-center pt-2">
                  <button class="fs-but1 pl-4 pr-4 pt-1 pb-1" @click="applyForLoan">VAY NGAY</button>
                  <p class="text pt-1 font-italic">Bằng cách bấm VAY NGAY, bạn đồng ý với <a class="text-primary" @click="$bvModal.show('modal-tima')">điều khoản và điều kiện</a> của Tima</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="tim-success-msg" v-if="!isFail">
              <p class="text-center pt-5"><svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M117.143 54.7758V60.033C117.136 72.3554 113.146 84.3454 105.768 94.2148C98.3898 104.084 88.019 111.304 76.2023 114.798C64.3856 118.292 51.756 117.872 40.1973 113.602C28.6385 109.332 18.7697 101.439 12.0629 91.1019C5.35609 80.7646 2.17051 68.5362 2.98129 56.2405C3.79206 43.9448 8.55575 32.2406 16.5619 22.8734C24.568 13.5062 35.3876 6.97803 47.4071 4.26239C59.4265 1.54676 72.0018 2.7892 83.2574 7.80442M117.143 14.3188L60.0003 71.5188L42.8574 54.3759" stroke="#23ED1F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></p>
              <h3>Đăng ký vay thành công</h3>
              <p class="text text-center">
                Quý Khách đã đăng ký vay thành công với<br>số điện thoại {{ item.phone }}
              </p>
              <div class="tima-box-1">
                Vui lòng giữ máy, Tima sẽ liên hệ ngay trong vòng 30 phút Giờ làm việc: 8h - 20h từ thứ 2 - chủ nhật (trừ ngày Lễ, Tết)
              </div>
              <p class="text text-center font-italic">
                Khi cần hỗ trợ bạn vui lòng liên lạc số điện thoại 1900 633 688
              </p>
              <p class="text-center pt-2">
                <a href="/" class="btn btn-primary mr-auto ml-auto">Về trang chủ</a>
              </p>
            </div>
            <div class="tim-success-msg" v-else>
              <p class="text-center pt-5"><svg width="118" height="119" viewBox="0 0 118 119" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M76.1431 42.3159L41.8574 76.6016M41.8574 42.3159L76.1431 76.6016M116.143 59.4587C116.143 91.0178 90.5594 116.602 59.0003 116.602C27.4412 116.602 1.85742 91.0178 1.85742 59.4587C1.85742 27.8996 27.4412 2.31586 59.0003 2.31586C90.5594 2.31586 116.143 27.8996 116.143 59.4587Z" stroke="#ED1F24" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </p>
              <h3>Bạn có đơn vay đang chờ xử lý!</h3>
              <p class="text text-center">
                Vui lòng liên hệ tới số:<br>
                1900.633.688<br>
                Để được hỗ trợ chi tiết
              </p>
              <p class="text-center pt-2">
                <a @click="submitted=0;isFail=false" class="btn btn-primary mr-auto ml-auto">Thử lại</a>
              </p>
              <p class="text-center pt-2">
                <a href="/" class="btn btn-secondary mr-auto ml-auto">Về trang chủ</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="tima-package-overlay" v-if="isShowPackage">
        <div class="tima-package" v-if="isShowPackage">
          <div class="tima-package-body">        
            <a class="close" role="button" @click="isShowPackage=!isShowPackage"></a>
            <h3 class="tima-package-header">Chọn gói vay</h3>
            <div class="tima-body-box">
              <span class="tima-radio" role="button" @click="type=2;selected_package=1;selected_period='09'" :class="type==2?'selected':''">Vay bằng đăng ký xe máy</span>
              <div class="row" v-if="type==2">
                <div class="col-12">
                  <div class=" pt-1 mt-1 text pb-1 border-top" v-if="packages.find(i=>i.id==selected_package)">Tôi cần vay <b>{{packages.find(i=>i.id==selected_package).long}}</b> VNĐ</div>
                </div>
                <div class="col-1 d-none d-lg-block"></div>
                <div class="col-2 col-lg-1" v-for="j in packages.filter(i=>i.type=='bike')" :key="j.id">
                  <b-form-radio name="bike" :value="j.id" v-model="selected_package"></b-form-radio><div class="mt-1 text">{{ j.short }}</div>
                </div>
                <div class="col-12">
                  <div class=" pt-1 mt-1 text pb-1">trong <b>{{selected_period}}</b> tháng</div>
                </div>
                <div class="col-2"></div>
                <div class="col-2" v-for="j in period" :key="j">
                  <b-form-radio name="bike_period" :value="j" v-model="selected_period"></b-form-radio><div class="mt-1 text">{{ j }}</div>
                </div>
                <div class="col-2"></div>
                <div class="text font-italic p-1">
                  Tima tư vấn gói vay theo đăng ký xe máy (cà vẹt xe máy), khoản vay đến 42 triệu lãi suất 1,5%/tháng, thời hạn vay từ 9 đến 12 tháng
                </div>
              </div>
              
            </div>
            <div class="tima-body-box mt-1 mb-2">
              <span class="tima-radio" role="button" @click="type=8;selected_package=11;selected_period='03'" :class="type==8?'selected':''">Vay bằng đăng ký ô tô</span>
              <div class="row" v-if="type==8">
                <div class="col-12">
                  <div class=" pt-1 mt-1 text pb-1 border-top">Tôi cần vay <b>{{packages.find(i=>i.id==selected_package).long}}</b> VNĐ</div>
                </div>
                <div class="col-1 d-none d-lg-block"></div>
                <div class="col-2 col-lg-1" v-for="j in packages.filter(i=>i.type!='bike')" :key="j.id">
                  <b-form-radio name="oto" :value="j.id" v-model="selected_package"></b-form-radio><div class="mt-1 text">{{ j.short }}</div>
                </div>
                <div class="col-12">
                  <div class=" pt-1 mt-1 text pb-1">trong <b>{{selected_period}}</b> tháng</div>
                </div>
                <div class="col-2"></div>
                <div class="col-2" v-for="j in periodOto" :key="j">
                  <b-form-radio name="oto_period" :value="j" v-model="selected_period" class="ml-2"></b-form-radio><div class="ml-2 mt-1 text">{{ j }}</div>
                </div>
                <div class="col-2"></div>
                <div class="text font-italic p-1">
                  Tima tư vấn gói vay theo đăng ký ô tô (cà vẹt ô tô). Hạn mức vay từ 50-500 triệu đồng. Thời hạn vay từ 3 - 12 tháng.
                </div>
              </div>
            </div>
            <div class="text-center mb-2">
              <button class="fs-but1 ml-auto mr-auto" @click="isShowPackage=!isShowPackage">XÁC NHẬN</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal body-class="p-2" hide-header size="lg" hide-footer id="modal-tima" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title">
            <img src="@/assets/images/logo/logo.svg" style="width:20%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
            <img src="@/assets/images/shop/partner/tima_web.png" style="width:30%" class="ml-2 mb-4" alt="icon-notify">
          </h5>
          <div class="text vietlott-info tima-text">
            <h2>PHẦN 1: QUY ĐỊNH CHUNG </h2>
            <br>Website vaytien.tima.vn (“Website”) được quản lý và vận hành hợp pháp bởi Công ty Cổ phần tập đoàn Tima (sau đây được gọi chung là “Tima”). 
            <br>Người sử dụng Website và các dịch vụ trên Website (sau đây có thể gọi là “bạn” hoặc “Khách hàng”) được yêu cầu phải đọc kỹ, hiểu, đồng ý và chịu sự ràng buộc với các quy định được mô tả trong Điều khoản và Điều kiện sử dụng đối với người sử dụng dịch vụ Tima (sau đây gọi là “Điều Khoản và Điều Kiện”). Bằng việc chọn “Tôi đã đọc và đồng ý” với “Điều Khoản và Điều Kiện”, Khách Hàng đồng ý vô điều kiện và theo đó xác nhận đã đọc kỹ, hiểu, đồng ý và chịu sự ràng buộc với các điều khoản và điều kiện sau: 
            <ul>
              <li>
                Ngay khi truy cập và sử dụng sản phẩm, dịch vụ trên Website, bạn đã xác nhận đồng ý và chấp thuận tuân thủ theo bảng Điều Khoản và Điều Kiện này của chúng tôi. Khi bạn đồng ý cung cấp thông tin và hình ảnh cho Tima nghĩa là bạn đồng ý sử dụng dịch vụ của chúng tôi, qua đó, cho phép Tima và đối tác (cá nhân hoặc tổ chức) của Tima khai thác và sử dụng các thông tin và hình ảnh đó để chào/bán các sản phẩm hay dịch vụ của họ và/hoặc của Tima, thông qua các phương tiện trực tiếp (gặp gỡ bán hàng) hoặc gián tiếp (email, điện thoại, tin nhắn, thư bưu điện…). Các đối tác của Tima có thể là các cá nhân và tổ chức hoạt động kinh doanh trong các lĩnh vực, bao gồm nhưng không giới hạn, cho vay, tài chính, tín dụng-ngân hàng, dịch vụ pháp lý, công nghệ thông tin và thương mại điện tử… do Tima chỉ định trọng từng thời kỳ. 
              </li>
              <li>
                Bằng việc sử dụng Website, Khách Hàng mặc nhiên thừa nhận Khách Hàng thuộc đối tượng hợp pháp để giao dịch với Tima và/hoặc các đối tác của Tima theo các quy định của Tima và quy định của các đối tác và quy định hiện hành của pháp luật Việt Nam. 
              </li>
              <li>
                Tima có toàn quyền thay đổi hoặc chấm dứt bất kỳ tính năng nào của Website, cũng như thay đổi các điều khoản và điều kiện áp dụng đối với người sử dụng Website này vào bất kỳ lúc nào và không cần báo trước. Những thay đổi, chỉnh sửa, bổ sung hoặc xóa bỏ sẽ có hiệu lực ngay sau khi được cập nhật và đăng tải lên Website… Mọi dịch vụ hay sản phẩm đang được bạn sử dụng thông qua Website sẽ phải chấp nhận các thay đổi cập nhật đó. 
              </li>
              <li>
                Tima cung cấp dịch vụ tư vấn cho các sản phẩm tài chính cho các khách hàng đang sinh sống và hoạt động trên lãnh thổ nước CHXHCN Việt Nam. Do vạy Tima không có nghĩa vụ phục vụ những cá nhân hay tổ chức ngoài phạm vi hoạt động mà không cần phải thông báo bằng văn bản hay bất kỳ phương tiện nào khác. 
              </li>
              <li>
                Khi bạn điền vào biểu mẫu và gửi yêu cầu, tạo một tài khoản hoặc đăng ký sử dụng dịch vụ qua Website, bạn đã hiểu và đồng ý rằng bạn đã thiết lập một mối quan hệ kinh doanh giữa bạn và Tima/đối tác của Tima. Theo đó, Tima và các đối tác có thể gửi thông tin và hình ảnh của bạn cho các nhà cung cấp sản phẩm và dịch vụ liên quan đến nhu cầu của bạn. Và bạn cũng chấp thuận để Tima và đối tác có thể liên lạc với bạn bằng cách sử dụng thông tin bạn đã cung cấp cho Tima. Vào bất cứ lúc nào bạn không muốn tiếp tục nhận thông tin liên lạc từ Tima, bạn có thể gọi điện trực tiếp vào số hotline của Tima để nêu ra yêu cầu của mình.
              </li>
              <li>
                Khi bạn gửi và lưu thông tin và hình ảnh của mình trên hệ thống Tima, bạn đã cho phép chúng tôi lưu giữ các thông tin và hình ảnh đó để thực hiện cuộc gọi trực tiếp, gửi email…để thảo luận, cung cấp hoặc thông báo cho bạn về các công việc liên quan đến yêu cầu của bạn. Khi yêu cầu bất kỳ dịch vụ nào từ Tima, bạn đã cam kết rằng tất cả các thông tin bạn đã cung cấp là chính và đầy đủ, phù hợp theo quy định của pháp luật Việt Nam. 
              </li>
              <li>
                Tima không đảm bảo rằng Website và bất kỳ sản phẩm dịch vụ do Tima cung cấp sẽ đáp ứng các yêu cầu của Khách Hàng hoặc đảm bảo rằng sự vận hành của Website này không bị gián đoạn, không có sự chậm trễ, từ chối lệnh, bị lỗi, mất hoặc bị hủy bỏ thông tin. Mặc dù việc xây dựng các thông tin được cung cấp cho Khách Hàng như “tại thời điểm” truy cập Website của Khách Hàng mà không có bất cứ sự bảo đảm nào dưới bất kỳ hình thức nào về việc không vi phạm, tính bảo mật, chính xác, phù hợp với một mục đích cụ thể nào đó hoặc không có vi rút máy tính liên quan đến các thông tin, tài liệu trên Website này. 
              </li>
            </ul> 
            <h2>PHẦN 2: CHÍNH SÁCH BẢO MẬT </h2>
            Các dữ liệu, thông tin cá nhân mà Bạn cung cấp cho Tima trong quá trình đăng ký sử dụng dịch vụ trên Website cũng như các dữ liệu di động mà bạn đồng ý cho Tima thu thập sẽ được xử lý và bảo mật phù hợp với quy định của pháp luật và Chính sách về quyền riêng tư (“Chính sách”) của chúng tôi.
          </div>
        </div>
        <div class="col-12 text-center pt-2 mt-1 mb-3">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-tima');">Đóng</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, min, email} from "@validations";
import {
  BFormInput,BFormRadio
} from "bootstrap-vue";
import vSelect from "vue-select";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import { $, val } from "dom7";
extend("min0", (value) => {
  return value > 0;
});
extend("phoneNumber", (value) => {
  if (value.length < 10 || value.length >= 11) return false;
  return /^0[0-9]+$/.test(value);
});

SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput,
    "v-select": vSelect,
    ValidationProvider, ValidationObserver,
    BFormRadio
  },
  data() {
    return {
      iframeSrc: '',
      submitCount: 0,
      submitted: false,
      isFail: false,
      item:{},
      required,
      min,
      email,
      province_id:0,
      district_id:0,
      type: 0,
      isShowPackage: false,
      selected_package: 0,
      selected_period: '09',
      packages:[
        {id:1, short: '3tr', long: '3,000,000',type:'bike'},
        {id:2, short: '6tr', long: '6,000,000',type:'bike'},
        {id:3, short: '9tr', long: '9,000,000',type:'bike'},
        {id:4, short: '12tr', long: '12,000,000',type:'bike'},
        {id:5, short: '15tr', long: '15,000,000',type:'bike'},
        {id:6, short: '18tr', long: '18,000,000',type:'bike'},
        {id:7, short: '21tr', long: '21,000,000',type:'bike'},
        {id:8, short: '24tr', long: '24,000,000',type:'bike'},
        {id:9, short: '27tr', long: '27,000,000',type:'bike'},
        {id:10, short: '42tr', long: '42,000,000',type:'bike'},

        {id:11, short: '50tr', long: '50,000,000',type:'oto'},
        {id:12, short: '100tr', long: '100,000,000',type:'oto'},
        {id:13, short: '150tr', long: '150,000,000',type:'oto'},
        {id:14, short: '200tr', long: '200,000,000',type:'oto'},
        {id:15, short: '250tr', long: '250,000,000',type:'oto'},
        {id:16, short: '300tr', long: '300,000,000',type:'oto'},
        {id:17, short: '350tr', long: '350,000,000',type:'oto'},
        {id:18, short: '400tr', long: '400,000,000',type:'oto'},
        {id:19, short: '450tr', long: '450,000,000',type:'oto'},
        {id:20, short: '500tr', long: '500,000,000',type:'oto'},
      ],
      period:[
        '09', '10', '11', '12'
      ],
      periodOto:[
        '03', '06', '09', '12'
      ],
    };
  },
  watch: {
    province_id: function(to, from) {
      this.district_id=null;
      this.$store.dispatch('shop/fetchDistrict',this);
    },
  },
  created() {

  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  computed: {
    provinces(){
      return this.$store.getters['shop/getProvinces']
    },
    districts(){
      return this.$store.getters['shop/getDistricts']
    },
    getPackageLabel(){
      if (this.selected_package==0 || this.type==0){
        return '';
      }else{
        return (this.type==2?'Đăng ký xe máy ':'Đăng ký ô tô ')+(this.packages.find(i=>i.id==this.selected_package).long)+'VNĐ - '+this.selected_period+' tháng';
      }
    }
  },
  mounted() {
    this.$store.dispatch('shop/fetchProvince',this);
    this.$store.dispatch('shop/fetchDistrict',this);
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    async applyForLoan(){
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      this.submitCount++;
      if (!validate || this.selected_package==0){
        var c = 0;
        if (this.item.full_name==null || this.item.full_name==''){
          c++;
        }
        if (this.item.cccd==null || this.item.cccd==''){
          c++;
        }
        if (this.item.phone==null || this.item.phone==''|| !/^0[0-9]{9}$/.test(this.item.phone)){
          c++;
        }
        if (this.province_id==null || this.province_id==''){
          c++;
        }
        if (this.item.email==null || this.item.email=='' || !/^\S+@\S+\.\S+$/.test(this.item.email)){
          c++;
        }
        if (this.district_id==null || this.district_id==''){
          c++;
        }
        if (c>=2){
          this.$toast.warning(
            "Bạn vui lòng kiểm tra lại và nhập lại các thông tin iTel khoanh đỏ nhé!",
            { icon: true, closeButton: "button" }
          );
          return;
        }
        if (this.item.full_name==null || this.item.full_name==''){
          this.$toast.warning(
            "Bạn vui lòng điền đầy đủ họ tên nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        if (this.item.cccd==null || this.item.cccd==''){
          this.$toast.warning(
            "Bạn vui lòng nhập số giấy tờ CMND/CCCD nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        if (this.item.phone==null || this.item.phone=='' || !/^0[0-9]{9}$/.test(this.item.phone)){
          this.$toast.warning(
            "Bạn vui lòng kiểm tra và nhập lại số điện thoại liên hệ nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        if (this.item.email==null || this.item.email=='' || !/^\S+@\S+\.\S+$/.test(this.item.email)){
          this.$toast.warning(
            "Bạn vui lòng kiểm tra và nhập địa chỉ email nhé!",
            { icon: true, closeButton: "button" }
          );
        }

        if (this.province_id==null || this.province_id==''){
          this.$toast.warning(
            "Bạn vui lòng chọn Tỉnh/TP nơi đang sinh sống nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        if (this.district_id==null || this.district_id==''){
          this.$toast.warning(
            "Bạn vui lòng chọn Quận/Huyện nơi đang sinh sống nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        if (this.selected_package==0){
          this.$toast.warning(
            "Bạn vui lòng chọn gói vay nhé!",
            { icon: true, closeButton: "button" }
          );
        }
        return;
      }
      this.$store.dispatch('shop/createLoan',this);
    }
  },
};
</script>
<style>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}
.b2c-shop-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.b2c-shop-pc-header {
  display: flex;
}
.b2c-shop-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}
.b2c-shop-pc-header .nav{
  background-image:none !important
}
.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}
.b2c-top-right {
  width: 20%;
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  line-height: 1.5;
  padding: 10px 30px
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}
.tima-left{
  background: url('~@/assets/images/shop/partner/tima_bg1.png') bottom no-repeat;
  background-size: cover;
  width: 542px;
  height: 542px;
  min-width: 542px;
}
.tima-right{
  padding: 26px;
}
.tima{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 30px;
}
.tima-header{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
  text-align: center;
}
.tima-input{
  border-bottom: 0.981143px solid #DBDBDB;
  height: 42px;
  font-weight: 500;
  font-size: 15px;
  color: #333;
  width: 100%;
}
.tima .vs__dropdown-toggle{
  border: none;
  border-bottom: 0.981143px solid #DBDBDB;
  height: 42px !important;
  font-weight: 500;
  font-size: 16px;
  color: #B5B5B5;
}
.tima .vs__selected{
  padding: 0 !important;
  margin-left: 0 !important;
}
.tima .vs__selected-options{
  padding: 0 !important;
}
.tima-package{
  background: #EBEBEB;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  width: 700px;
  position: fixed;
  z-index: 1000;
  min-height: 200px;
  top: calc(20vh - 100px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media screen and (max-width: 719px){
  .tima-package{
    width: 100%;
  }
}
.tima-package-body{
  position: relative;
  width: 100%;
  padding: 8px;
}
.tima-package-body .close{
  width: 15px;
  height: 15px;
  right: 15px;
  top: 15px;
  position: absolute;
}
.tima-package-body .close::after{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNjU0NTIgOC4yOTYwOEwxNi4wMjI3IDEuOTI3ODZDMTYuMTY1OCAxLjc2MDc0IDE2LjI0MDYgMS41NDU3OCAxNi4yMzIxIDEuMzI1OTNDMTYuMjIzNiAxLjEwNjA4IDE2LjEzMjUgMC44OTc1MjYgMTUuOTc2OSAwLjc0MTk1QzE1LjgyMTQgMC41ODYzNzUgMTUuNjEyOCAwLjQ5NTIzNCAxNS4zOTI5IDAuNDg2NzQyQzE1LjE3MzEgMC40NzgyNSAxNC45NTgxIDAuNTUzMDMyIDE0Ljc5MSAwLjY5NjE0NEw4LjQyMjggNy4wNjQzNkwyLjA1NDU4IDAuNjg3NDA4QzEuODg3NDcgMC41NDQyOTcgMS42NzI1MSAwLjQ2OTUxNSAxLjQ1MjY2IDAuNDc4MDA3QzEuMjMyOCAwLjQ4NjQ5OSAxLjAyNDI1IDAuNTc3NjM5IDAuODY4Njc3IDAuNzMzMjE1QzAuNzEzMTAyIDAuODg4NzkgMC42MjE5NjEgMS4wOTczNCAwLjYxMzQ2OSAxLjMxNzE5QzAuNjA0OTc3IDEuNTM3MDUgMC42Nzk3NTkgMS43NTIwMSAwLjgyMjg3MSAxLjkxOTEyTDcuMTkxMDkgOC4yOTYwOEwwLjgxNDEzNSAxNC42NjQzQzAuNzIyNjkgMTQuNzQyNiAwLjY0ODQyIDE0LjgzOSAwLjU5NTk4NiAxNC45NDc0QzAuNTQzNTUyIDE1LjA1NTcgMC41MTQwODcgMTUuMTczOCAwLjUwOTQ0IDE1LjI5NDFDMC41MDQ3OTMgMTUuNDE0NCAwLjUyNTA2NSAxNS41MzQ0IDAuNTY4OTgyIDE1LjY0NjVDMC42MTI5IDE1Ljc1ODYgMC42Nzk1MTYgMTUuODYwNCAwLjc2NDY0OCAxNS45NDU1QzAuODQ5NzggMTYuMDMwNiAwLjk1MTU5MSAxNi4wOTcyIDEuMDYzNjkgMTYuMTQxMkMxLjE3NTc5IDE2LjE4NTEgMS4yOTU3NiAxNi4yMDU0IDEuNDE2MDYgMTYuMjAwN0MxLjUzNjM3IDE2LjE5NjEgMS42NTQ0MSAxNi4xNjY2IDEuNzYyNzkgMTYuMTE0MkMxLjg3MTE3IDE2LjA2MTcgMS45Njc1NCAxNS45ODc1IDIuMDQ1ODUgMTUuODk2TDguNDIyOCA5LjUyNzc5TDE0Ljc5MSAxNS44OTZDMTQuOTU4MSAxNi4wMzkxIDE1LjE3MzEgMTYuMTEzOSAxNS4zOTI5IDE2LjEwNTRDMTUuNjEyOCAxNi4wOTY5IDE1LjgyMTQgMTYuMDA1OCAxNS45NzY5IDE1Ljg1MDJDMTYuMTMyNSAxNS42OTQ2IDE2LjIyMzYgMTUuNDg2MSAxNi4yMzIxIDE1LjI2NjJDMTYuMjQwNiAxNS4wNDY0IDE2LjE2NTggMTQuODMxNCAxNi4wMjI3IDE0LjY2NDNMOS42NTQ1MiA4LjI5NjA4WiIgZmlsbD0iIzk5OTk5OSIvPgo8L3N2Zz4K');
}
.tima-package-header{
  padding-top: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
  padding-bottom: 20px;
  color: #555555;
}
.tima-body-box{
  background: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  min-height: 66.96px;;
  padding: 22px;
  vertical-align: middle;
  display: inline-block;
}
.tima-body-box .tima-radio{
  line-height: 22px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}
.tima-radio::before{
  vertical-align: -7px;
  padding-right: 23px;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC42ODk0NTMiIHk9IjEuNDc5MjUiIHdpZHRoPSIxOS41IiBoZWlnaHQ9IjE5LjUiIHJ4PSI5Ljc1IiBzdHJva2U9IiNDNUM1QzUiLz4KPC9zdmc+Cg==')
}
.tima-radio.selected::before{
  vertical-align: -7px;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMiAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC43NDY1ODIiIHk9IjAuNDA1MDI5IiB3aWR0aD0iMjAuNSIgaGVpZ2h0PSIyMC41IiByeD0iMTAuMjUiIGZpbGw9IiNFRDFGMjQiLz4KPHBhdGggZD0iTTE1LjQ1NjMgOC4xMjU3M0w5Ljk2NTE4IDEzLjYxNjhMNy40NjkyNCAxMS4xMjA5IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')
}
.tima-body-box .text{
  font-size: 14px;
}
.tima .is-invalid{
  border:none !important;
  border-bottom: 1px solid #ed1f24 !important;
  border-radius: 0;
}
.tima .is-invalid::before {
  position: absolute;
  right: 10px;
  top: 18px;
  bottom: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e") !important
}
.tima .valid::before {
  position: absolute;
  right: 16px;
  top: 18px;
  bottom: 0;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgcm9sZT0icHJlc2VudGF0aW9uIiBjbGFzcz0idnNfX29wZW4taW5kaWNhdG9yIj48cGF0aCBkPSJNOS4yMTEzNjQgNy41OTkzMWw0LjQ4MzM4LTQuODY3MjI5Yy40MDcwMDgtLjQ0MTg1NC40MDcwMDgtMS4xNTgyNDcgMC0xLjYwMDQ2bC0uNzM3MTItLjgwMDIzYy0uNDA3MDA4LS40NDE4NTQtMS4wNjY5MDQtLjQ0MTg1NC0xLjQ3NDI0MyAwTDcgNS4xOTg2MTcgMi41MTY2Mi4zMzEzOWMtLjQwNzAwOC0uNDQxODUzLTEuMDY2OTA0LS40NDE4NTMtMS40NzQyNDMgMGwtLjczNzEyMS44MDAyM2MtLjQwNzAwOC40NDE4NTQtLjQwNzAwOCAxLjE1ODI0OCAwIDEuNjAwNDYxbDQuNDgzMzggNC44NjcyMjhMNyAxMGwyLjIxMTM2NC0yLjQwMDY5eiIgZmlsbD0icmdiYSg2MCwgNjAsIDYwLCAuNSkiPjwvcGF0aD48L3N2Zz4=") !important
}
.tima .is-invalid.v-select::before{
  right: 0px;
}
.tima .is-invalid .vs__actions{
  display: none;
}
.tima .vs__search{
  padding: 0;
}
.tim-success-msg.app{
  width: 100%;
  height: 610.93px;
}
.tim-success-msg{
  width: 504px;
  height: 570.93px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.tim-success-msg svg{
  margin-left: auto;
  margin-right: auto;
  display: block
}
.tim-success-msg h3{
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 28px;
}
.tim-success-msg .tima-box-1{
  font-weight: 400;
  font-size: 15px;
  padding: 25px;
  line-height: 25px;
  margin: 20px 44px;
  background: #FFF7F4;
  border: 1px solid #ED5B23;
  border-radius: 8px;
}
.tim-success-msg a.btn{
  border-radius: 30px !important;
  padding-right: 50px;
  padding-left: 50px;
}
.tima-text h2{
  font-size: 18px !important;
  font-weight: 800;
  padding-top:12px
}
.tima-text{
  line-height: 22px;
}
.tima-text li{
  margin-left: 20px;
}
.tima-text ul {
  list-style-type: square;
}
.tima-app .b2c-top-img{
  width: 100%;
}
.tima-app, .tima-app .b2c-bg-top, .tima-app .b2c-top-center{
  background: #fff !important;
  color: #333 !important;
}
.tima-app .b2c-top-left::before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDggMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzLjk3MTJMMSA3Ljk3MTE5TDcgMS45NzExOSIgc3Ryb2tlPSIjNDI0MjQyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}
.tima-app .b2c-bg-top{
  margin-top: 10px;
}
.tima-app .fs-inr{
  margin-top:-80px;
  z-index: 1000;
}
.tima-package .text{
  line-height: 22px;
}
input.tima-input, input.vs__search, .tima-input-btn{
  font-weight: 500;
  color: #333;
}
input.tima-input::placeholder, input.vs__search::placeholder {
  color: #888 !important;
}
.tima-package-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0,0,0,0.5);
}
</style>